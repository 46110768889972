import styled from 'styled-components';
import FlexBox from '../../atoms/FlexBox';
import Button from '@app/components/atoms/Button';

export const Container = styled.div<{ hasBgColor?: boolean }>`
  position: relative;
  background-color: ${({ hasBgColor }) => (hasBgColor ? '#fff' : 'unset')};
  z-index: 2;
`;

export const Content = styled.div<{ lessMargin?: boolean; wide?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin: ${({ lessMargin }) =>
    lessMargin ? '0px 18px 10px' : '0px 18px 50px'};
  padding-bottom: ${({ lessMargin }) => (lessMargin ? 0 : 20)}px;
  padding-top: 10px;

  @media screen and (max-width: 2100px) {
    margin: ${({ wide }) => (wide ? '0px 20px' : '0px 18px')};
  }

  @media screen and (max-width: 1590px) {
    margin: ${({ lessMargin }) =>
      lessMargin ? '0px 18px' : '0px 18pxpx 20px'};
  }

  @media screen and (max-width: 975px) {
    margin: 0px 18px 10px;
  }
`;

export const ImageContainer = styled.div`
  height: 65px;
  width: 65px;
  position: relative;
  cursor: pointer;
  margin-right: 50px;
  @media screen and (max-width: 975px) {
    height: 50px;
    width: 50px;
    margin-right: 20px;
  }
`;

export const ButtonsWrapper = styled.div<{
  margin?: boolean;
  showMobile?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  font-family: 'Inter';
  margin-top: ${({ margin }) => (margin ? 12 : 0)}px;

  @media screen and (max-width: 975px) {
    display: ${({ showMobile }) => (showMobile ? 'flex' : 'none')};
  }
`;

export const ButtonContainer = styled.div<{
  active?: boolean;
}>`
  padding-bottom: 10px;
  margin-top: 10px;
  &:not(:last-child) {
    margin-right: 31px;
  }

  @media screen and (max-width: 975px) {
    &:not(:last-child) {
      margin-right: 23px;
    }
  }

  border-bottom: ${({ active }) => (active ? '3px solid #2CD1C0' : 'unset')};
`;

export const MarginHeaderButton = styled.div`
  margin-right: 25px;
`;

export const MobileWrapper = styled.div`
  display: flex;

  @media screen and (min-width: 975px) {
    display: none;
  }
`;

export const Separator = styled.div`
  border: 1px solid #a6a6a6;
  height: 20px;
`;

export const AnnouncementContainer = styled.div`
  width: 100%;
  font-family: 'Inter';
  padding: 10px 0 10px 0;
  background-color: #4f4f4f;

  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 975px) {
    margin-bottom: 10px;
  }
`;

export const Announcement = styled.div`
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;

  width: 100%;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 975px) {
    font-size: 14px;
  }
`;

export const AnnouncementText = styled.div`
  margin-left: 5px;
`;

export const AnnouncementImg = styled.div`
  margin-right: 5px;
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
`;

export const SuperUserContainer = styled(FlexBox)`
  position: absolute;
  top: 0;
  margin: 0 auto;
  background: #e59231;
  height: 35px;
  padding: 0 26px;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
`;

export const MyTrustyButton = styled(Button)`
  gap: 12px;
  margin-top: -10px;
  display: flex;
  align-items: center;
`;
